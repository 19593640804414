import { KeyboardEvent, useEffect, useState } from 'react';
import { useTrackedState } from '../../store';
import SendIcon from '@mui/icons-material/Send';
import styled from 'styled-components';

export const ChatInputAreaWrapper = styled.div`
padding: 16px;
`;

export const ChatInputArea = styled.div`
display: flex;
border-radius: 50px;
padding: 8px;
box-shadow: var(--navigation-box-shadow);
background-color: #F1F2F8;
`;

export const ChatInputBox = styled.input`
border: none;
font-size: 14px;
line-height: 24px;
outline: none;
color: var(--message-text);
flex: 1;
background-color: transparent;
padding: 0 10px;

:placeholder {
  color: var(--placeholder-text);
}
`;

export const ChatSentButton = styled.button`
color: white;
border-radius: 50%;
background: #11304F;
border: none;
width: 32px;
height: 32px;
// padding: 8px;
display: flex;
justify-content: center;
align-items: center;

svg {
  width: 16px;
  height: 16px;
  transform: translate(1px,0);
}
`;

export const ChatInput = () => {
  const state = useTrackedState();
  const [message, setMessage] = useState('');

  function _handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      onSubmit();
    }
  }

  function onSubmit() {
    state.uneeq.stopRecording();
    state.uneeq.sendTranscript(message);
    setMessage('');
  }

  return (
    <ChatInputAreaWrapper>
      <ChatInputArea>

        {/* Chat input area to sent texts */}
        <ChatInputBox value={message}
          onChange={(e) => { setMessage(e.target.value) }}
          type="text"
          placeholder="Type your message..."
          onKeyDown={(e) => _handleKeyDown(e)}>
        </ChatInputBox>

        {/* Custom send button for inputbox */}
        <ChatSentButton onClick={(e) => onSubmit()}>
          <SendIcon />
        </ChatSentButton>
      </ChatInputArea>
    </ChatInputAreaWrapper>
  )
}
