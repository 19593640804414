import styled from "styled-components";

export const VideoCallWraper = styled.div`
width: 100%;
height: 100%;
border-radius: 2px;
overflow: hidden;
display: flex;
flex-wrap: wrap;
text-align: center;
`;

export const VideoParticipant = styled.div`
display: flex;
justify-content: center;
width: 100%;
height: 120%;
position: absolute;
left: 0;
top: -120px;
z-index: 0;
background:#F8F8FA;

img {
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 520px) and (max-height: 720px) {
  .video-participant {
    width: 100%;
    height: 100%;
  }
}
`;

export const AppRhea: React.FC = () => {
  return (
    <VideoCallWraper>
      <VideoParticipant id='rhea'></VideoParticipant>
    </VideoCallWraper>
  );
}