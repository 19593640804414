/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
// import Peer from 'peerjs';
import Hark from 'hark';
import styled from 'styled-components';

import { useSetState, useTrackedState } from '../store';
import uneeq from '../uneeq/uneeq';
import ChatBox from './ChatBox';
import { AppRhea } from './Avatar';
import { CallActions } from './Actions';
import { CallLoader } from './Loader';
import { Header } from './Header';
import { Video } from './Video';
import { Toolbar } from '@mui/material';

import EltropyLogo from '../../../assets/images/eltropy.png';

export const ApplicationContent = styled.div`
background-color: var(--app-background);
width: 100%;
height: 100%;
font-family: "Roboto", sans-serif;
display: flex;
transition: 0.2s;
`;

export const ApplicationMainContent = styled.div`
flex: 1;
width: 100%;
padding: 72px 32px 32px 32px;
display: flex;
flex-direction: column;
align-items: center;

@media screen and (max-width: 520px) {
    padding: 72px 16px 32px 0px;
}
`;

let uneeqObj: any;
let mediaStream: any;

export const ApplicationContainer = (props: any) => {
    const [loading, setLoading] = useState(true);
    const autoplayRef: React.MutableRefObject<null> = useRef(null);
    const [toggleChat, setToggleChat] = useState(true);
    const setState = useSetState();
    const [chatEnable, setChatEnable] = useState(false);
    const [videoEnable, setVideoEnable] = useState(true);
    const [micEnable, setMicEnable] = useState(true);

    useEffect(() => {
        // Avatar initialisation
        uneeqObj = uneeq.initialize(fmMessageHandler);
        setState((prev) => ({ ...prev, uneeq: uneeqObj }));

        function fmMessageHandler(msg: any) {
            switch (msg.uneeqMessageType) {

                case 'Ready':
                    // console.log("Ready")
                    break;

                case 'SessionLive':
                    // Session live event
                    setLoading(false);
                    initializeVad();
                    break;

                case 'AvatarQuestionText':
                    // console.log("AvatarQuestionText", msg.question);
                    const splitArrayQuestion: string[] = msg.question.split(". ");
                    splitArrayQuestion.forEach((element: string, idx: number) => {
                        let tempElement = element;
                        if ((splitArrayQuestion.length > 1) && (idx < (splitArrayQuestion.length - 1))) {
                            tempElement = tempElement + "."
                        }
                        setState(prev => ({
                            ...prev, transcription: [...prev.transcription, {
                                transcript: tempElement, user: "user"
                            }]
                        }))
                    });
                    break;

                case 'AvatarAnswer':
                    // console.log('AvatarAnswer', msg.answerSpeech)
                    const splitArrayAnswer: string[] = msg.answer.split(". ");
                    splitArrayAnswer.forEach((element: string, idx: number) => {
                        let tempElement = element;
                        if ((splitArrayAnswer.length > 1) && (idx < (splitArrayAnswer.length - 1))) {
                            tempElement = tempElement + "."
                        }
                        setState(prev => ({
                            ...prev, transcription: [...prev.transcription, {
                                transcript: tempElement, user: "rhea"
                            }]
                        }))
                    });
                    break;

                case 'AvatarAnswerMessage':
                    // console.log("Answer",msg.content)
                    break;

                case 'FinishedSpeaking':
                    triggerEvent();
                    break;

                default:
                    console.log("Default Uneeq Message", msg)
            }
        }

        async function initializeVad() {
            mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false
            })
                .then((stream) => {
                    var options = {
                        interval: 100,
                        threshold: -50,
                        play: false
                    };
                    var speechEvents = Hark(stream, options);
                    setState(prev => ({ ...prev, harkObj: speechEvents }));

                    speechEvents.on('speaking', function () {
                        console.log('speaking');
                    });

                    speechEvents.on('stopped_speaking', function () {
                        if (!chatEnable) {
                            uneeqObj.stopRecording();
                        }
                    });
                })
                .catch((err) => {
                    /* handle the error */
                });
        }

        return () => {
            mediaStream?.getTracks().forEach(function (track: { stop: () => void; }) {
                track.stop();
            });
        }
    }, [])

    function triggerEvent() {
        if (!chatEnable) {
            uneeqObj.startRecording();
        } else {
            uneeqObj.stopRecording();
        }
    }

    return (
        <ApplicationContent>
            {/* This button ref to make actions on the page before playing any audio or video element - Browser fix chrome */}
            <button style={{ visibility: 'hidden', opacity: '0', width: '0', height: '0' }} ref={autoplayRef}></button>

            {/* Header component with logo */}
            <Header />

            <div className="footer" style={{ position: "absolute", bottom: "32px", left: "20px", zIndex:1 }}>
                Powered by <img  src={EltropyLogo} style={{height:32}} alt="fireSpot"/>
            </div>

            {/* Call loading overlay till session live event from Uneeq */}
            {loading && <CallLoader />}
            <ApplicationMainContent>
                {/* Main Rhea video component */}
                <AppRhea />
                {/* Call actions component */}
                <CallActions videoEnable={videoEnable}
                    setVideoEnable={setVideoEnable}
                    chatEnable={chatEnable}
                    setChatEnable={setChatEnable}
                    micEnable={micEnable}
                    setMicEnable={setMicEnable} />
                {/* Chat box component */}
                <ChatBox {...props}
                    micEnable={micEnable}
                    togglechat={toggleChat}
                    settogglechat={setToggleChat} />

                {/* Video element container for streraming user video     */}
                {(videoEnable && !chatEnable) && <Video />}
            </ApplicationMainContent>
        </ApplicationContent>
    )
}
