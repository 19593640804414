import ScrollableFeed from 'react-scrollable-feed'
import { MessageWrapper } from './Message';
import styled from 'styled-components';

export const ChatAreaContainer = styled.div`
flex: 1;
overflow-y: auto;
padding: 16px;
`;

export const ChatArea = (props: { transcript: any[]; }) => {
    return(
        <ScrollableFeed>
            <ChatAreaContainer>
            {props.transcript?.map((value,idx) => {
                return(
                    value.transcript &&
                    (<MessageWrapper 
                    key={idx} 
                    message={value.transcript}
                    user={value.user}
                    enableName={value.user === props.transcript[idx+1]?.user}
                    reverse={value.user === 'user' ? true : false}/>)
                )
            })}
            </ChatAreaContainer>
        </ScrollableFeed>
    )
}
