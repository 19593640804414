import { Uneeq, UneeqOptions } from "uneeq-js";
import { accessToken } from "./getToken";

let uneeqObj:any = null;
class uneeq {
    initialize(Handler: (arg0: any) => any) {
        const options: UneeqOptions = {
            url: process.env.REACT_APP_UNEEQ_SERVER || "/",
            conversationId: process.env.REACT_APP_UNEEQ_PERSONNA || "",
            avatarVideoContainerElement: document.getElementById('rhea') as HTMLDivElement,
            localVideoContainerElement: document.createElement('div'),
            messageHandler: (msg) => Handler(msg),
            playWelcome: true,
            sendLocalVideo: false,
            sendLocalAudio: true,
            enableTransparentBackground:true
          }

          uneeqObj = new Uneeq(options);
        
          accessToken.then(response => {
            let token = response.data.token;
            if(token) {
              // console.log('Response data object',token);
              uneeqObj.initWithToken(token);
            } else {
              // console.log('No token');
            }
          });
          return uneeqObj;
    }
}

export default new uneeq();
