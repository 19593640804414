import { useState } from 'react';
import { createContainer } from 'react-tracked';

export interface TranscriptionInterface {
  transcript: string,
  user: string
}

export interface TranscriptionArray extends Array<TranscriptionInterface> { };

export type State = {
  uneeq: any;
  transcription: TranscriptionArray;
  chatMode: boolean;
  harkObj: any;
  feedback: boolean;
}

const initialState = {
  uneeq: null,
  transcription: [{ transcript: '', user: 'user' }],
  chatMode: false,
  harkObj: null,
  feedback:false
}

const useValue = () => useState<State>(initialState);

export const {
  Provider: UneeqProvider,
  useTrackedState,
  useUpdate: useSetState,
} = createContainer(useValue);