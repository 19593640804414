import CircularProgress from '@mui/material/CircularProgress';
import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export const VideoCallWrapper = styled.div`
position:absolute;
width: 250px;
height: 166.7px;
bottom: 72px;
left: 20px;
border-radius: 16px;
overflow: hidden;
display: flex;
flex-wrap: wrap;
`;

export const VideoParticipant = styled.div`
position: relative;
width: 100%;
height:100%;

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`;

export const VideoLoading = styled.div`
position: absolute;
top: 0;
left: 0;
width:100%;
height: 100%;
background: rgba(0,0,0,0.4);
display:flex;
justify-content: center;
align-items:center;
z-index:99;
`;

export const ParticipantActions = styled.div`
position: absolute;
display: flex;
left: 12px;
top: 12px;
`;

export const VideoContainer = styled.video`
// transform: rotateY(180deg);
// -webkit-transform:rotateY(180deg); /* Safari and Chrome */
// -moz-transform:rotateY(180deg); /* Firefox */
`;

export const VideoNameTag = styled.a`
position: absolute;
bottom: 12px;
right: 12px;
font-size: 12px;
color: #fff;
background-color: rgba(0,15,47,0.5);
border-radius: 4px;
padding: 4px 12px;
`;

let mediaStream: MediaStream;

export const Video: React.FC = () => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        enableVideoStream();

        return () => {
            console.log("media unmounted", mediaStream)
            mediaStream.getTracks().forEach(function (track) {
                track.stop();
                console.log("trracks stopped")
            });
        }
    }, [])
    async function enableVideoStream() {
        mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
        const video = document.getElementById('eltropy-admin-video') as HTMLMediaElement;
        if ('srcObject' in video) {
            video.srcObject = mediaStream;
            video.onloadedmetadata = () => {
                video.play();
                setLoading(false);
              };
        }
    }
    return (
        <VideoCallWrapper>
            <VideoParticipant>
                {loading && (
                    <VideoLoading>
                        <CircularProgress size={24} />
                    </VideoLoading>
                )}
                <ParticipantActions></ParticipantActions>
                <VideoNameTag>Jason</VideoNameTag>
                <VideoContainer id="eltropy-admin-video"></VideoContainer>
            </VideoParticipant>
        </VideoCallWrapper>
    )
}