import { useState } from 'react';
import { createContainer } from 'react-tracked';

export type State = {
  feedback: boolean;
}

const initialState = {
  feedback:false
}

const useValue = () => useState<State>(initialState);

export const {
  Provider: UniversalProvider,
  useTrackedState,
  useUpdate: useSetState,
} = createContainer(useValue);