import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { AppMain } from "./pages/app";
import { OnBoarding } from "./pages/on-board";
import styled from "styled-components";

export const AppContent = styled.div`
height:100%;
`;

export const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <AppContent id="mv-iva-demo">
          <Route exact path="/live">
            {/* Main application component with avatar and functions */}
            <AppMain />
          </Route>
          <Route exact path="/">
            {/* Initial onboarding page before application start */}
            <OnBoarding />
          </Route>
        </AppContent>
      </Switch>
    </BrowserRouter>
  );
}
