import classNames from 'classnames';
import styled from 'styled-components';

export const TextCapitalize = styled.p<{ right: boolean }>`
text-transform: capitalize;
font-size: 12px;
font-weight: 400;
color:#B6B9C3;
margin:5px;
text-align:${props => (props.right ? "right" : "left")}
`;

export const MessageWrapper = (props: { reverse: any; user: string; message: string; enableName: boolean }) => {
  const wrapperClasses = classNames({
    "message-wrapper": true,
    "reverse": props.reverse
  })
  const messageClass = classNames({
    "message": true,
    "bg-dark text-white": props.user === "agent"
  })
  const message = props.message.replaceAll(/\.\s/g, '.<br>');

  function createMarkup() {
    return { __html: message };
  }

  return (
    <div className={wrapperClasses}>
      <div className="message-content">
        <div className={messageClass} dangerouslySetInnerHTML={createMarkup()}></div>
        {!props.enableName && (
          <TextCapitalize right={props.user === 'user' ? true : false}>
            {props.user === 'user' ? "You" : "Kaitlin"}
          </TextCapitalize>
        )}
      </div>
    </div>
  )
}
