import React, { useEffect, useState } from 'react';
// import { useTrackedState } from '../store';
import styled from 'styled-components';
import { useSetState, useTrackedState } from '../store';
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ChatBubbleOutlinedIcon from '@mui/icons-material/ChatBubbleOutlined';

export const CallActionsContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 500px;
background:#11304F;
padding: 8px;
border-radius:50px;
z-index: 100;

@media screen and (max-width: 520px) {
  padding-top: 32px;
}
`;

const CallActionBtns = styled.button`
display: inline-block;
height: 100%;
width: 100%;
color:white;
border-radius:50%;
height:42px;
width:42px;
margin:0 5px;
display: flex;
justify-content: center;
align-items: center;
cursor:pointer;
border: none;

:hover {
  opacity:0.8;
}
`;

const MicrophoneBtn = styled(CallActionBtns) <{ chat: boolean }>`
background:${props => !props.chat ? "transparent" : "#EF5A51"};
border-radius: 50px;
width:100%;
padding: 0 10px;

span {
  padding: 0 10px;
}
`;

const ChatBtn = styled(CallActionBtns) <{ chat: boolean }>`
background: transparent;
border-radius: 50px;
padding: 0 16px;
width:100%
`;

export type CallActionsProp = {
  chatEnable: boolean;
  setChatEnable: any;
  videoEnable: boolean;
  setVideoEnable: any;
  micEnable: boolean;
  setMicEnable: any;
}

export const CallActions: React.FC<CallActionsProp> = ({
  chatEnable,
  setChatEnable,
  setVideoEnable,
  videoEnable,
  micEnable,
  setMicEnable
}) => {
  const setState = useSetState();
  const state = useTrackedState();

  // Use effect to enable and disable microphone operations
  useEffect(() => {
    if (micEnable && state.uneeq) {
      setChatEnable(false);
      state.uneeq.enableMicrophone(true);
      state.uneeq.startRecording();
    } else {
      state.uneeq && state.uneeq.stopRecording();
      state.uneeq && state.uneeq.enableMicrophone(false);
    }
  }, [micEnable])

  useEffect(() => {
    setState((prev: any) => ({ ...prev, chatMode: chatEnable }));
    if (chatEnable) {
      setMicEnable(false);
      setVideoEnable(false);
      state.uneeq && state.uneeq.stopRecording();
      state.uneeq && state.uneeq.enableMicrophone(false);
    } else {
      if (micEnable) {
        state.uneeq && state.uneeq.enableMicrophone(true);
      }
      state.uneeq && state.uneeq.startRecording();
    }
  }, [chatEnable])

  const onChangeMic = () => {
    setMicEnable((prev: any) => (!prev));
  }

  const onChangeVideo = () => {
    setVideoEnable((prev: any) => (!prev));
  }

  const onChangeChat = () => {
    setChatEnable((prev: any) => (!prev));
  }

  return (
    <CallActionsContainer>
      {/* Microphone call action */}
      <MicrophoneBtn chat={state.chatMode || !micEnable} onClick={onChangeMic}>
        {(state.chatMode || !micEnable) ? <MicOffOutlinedIcon /> : <KeyboardVoiceOutlinedIcon />}
        {/* <span>
          <AudioVisualizerComponent enable={state.chatMode} />
        </span> */}
      </MicrophoneBtn>

      <MicrophoneBtn chat={state.chatMode || !videoEnable} onClick={onChangeVideo} disabled={state.chatMode}>
        {(state.chatMode || !videoEnable) ? <VideocamOffOutlinedIcon /> : <VideocamOutlinedIcon />}
      </MicrophoneBtn>

      <ChatBtn chat={state.chatMode} onClick={onChangeChat}>
        {state.chatMode ? <ChatBubbleOutlinedIcon /> : <ChatOutlinedIcon />}
      </ChatBtn>

    </CallActionsContainer>
  )
}