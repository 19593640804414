import React, { useEffect, useRef } from 'react';
import { init, resetWaveform } from '../audio-visualizer/AudioVisualizer';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';

import '../audio-visualizer/waveform.css';
import styled from 'styled-components';

export const WaveformContainer = styled.div`
width: 100%;
height: 100%;
margin: 20px 0;
height: 50px;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;

p {
    color: var(--message-bg-2);
}
`;

export const MutedMicrophoneContainer = styled(WaveformContainer)`
color:lightgrey;
`;

export type AudioVisualizerProps = {
    enable: boolean
}

export const AudioVisualizerComponent: React.FC<AudioVisualizerProps> = ({ enable }) => {
    const waveform = useRef<HTMLDivElement>(null);
    useEffect(() => {
        init(waveform);
    }, [enable])

    return (
        enable ?
            <WaveformContainer className="waveform-container">
                <div className="waveform" ref={waveform}></div>
                <p>Listening...</p>
            </WaveformContainer>
            :
            <MutedMicrophoneContainer>
                <MicOffOutlinedIcon />
                <span>Muted</span>
            </MutedMicrophoneContainer>
    )
}