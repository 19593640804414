import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';

export const LoadingScreen = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: white;
z-index: 999;
`;

export const CallLoader = () => {
    return (
        <LoadingScreen>
            <CircularProgress />
            <p className="display-7 my-3">Connecting</p>
        </LoadingScreen>
    )
}