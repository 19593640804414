import { MutableRefObject, RefObject } from "react";

export class AudioVisualizer {
  audioContext: AudioContext;
  processFrame: (data: any) => void;
  analyser: AnalyserNode | null;
  constructor(audioContext: AudioContext, processFrame: (data: any) => void) {
    this.audioContext = audioContext;
    this.processFrame = processFrame;
    this.connectStream = this.connectStream.bind(this);
    this.analyser = null;

    // Send stream to connect to audio analyser
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      .then(this.connectStream)
      .catch((error) => {
        console.log("Error in waveform")
      });
  } //Constructor end

  // connects the stream to the source
  connectStream(stream: MediaStream) {
    this.analyser = this.audioContext.createAnalyser();
    const source = this.audioContext.createMediaStreamSource(stream);
    source.connect(this.analyser);
    this.analyser.smoothingTimeConstant = 0.5;
    this.analyser.fftSize = 1024;

    this.initRenderLoop(this.analyser);
  } //connect stream end

  initRenderLoop(localAnalyser: any) {
    const frequencyData = new Uint8Array(localAnalyser.frequencyBinCount);
    const processFrame = this.processFrame || (() => { });

    const renderFrame = () => {
      localAnalyser.getByteFrequencyData(frequencyData);
      processFrame(frequencyData);

      requestAnimationFrame(renderFrame);
    };
    requestAnimationFrame(renderFrame);
  } //init render loop end
} //audio visualizer class end

const visualValueCount = 13;

//Create dom elements start
const createDOMElements = (waveform: RefObject<HTMLDivElement>) => {
  let i;
  for (i = 0; i < visualValueCount; ++i) {
    const elm = document.createElement('div');
    waveform.current?.appendChild(elm);
  }
}; //create don elements end

export const resetWaveform = (waveform: RefObject<HTMLDivElement>) => {
  const initDOM = () => {
    for (let i = 0; i < 13; ++i) {
      const elm = document.createElement('div');
      waveform.current?.appendChild(elm);
    }
  };
  initDOM();
}

// init start
export const init = (waveform: RefObject<HTMLDivElement>) => {
  // Creating initial DOM elements
  const audioContext = new AudioContext();
  const initDOM = () => {
    { waveform.current && (waveform.current.innerHTML = '') }
    createDOMElements(waveform);
  };
  initDOM();

  // Swapping values around for a better visual effect
  const dataMap: any = { 0: 15, 1: 14, 2: 13, 3: 12, 4: 11, 5: 10, 6: 9, 7: 10, 8: 11, 9: 12, 10: 13, 11: 14, 12: 15 };

  // Process frames for styling the div elements according to the audio
  const processFrame = (data: { [s: string]: unknown; } | ArrayLike<unknown>) => {
    let visualElements: NodeListOf<HTMLDivElement>;
    visualElements = document.querySelectorAll('.waveform div');
    const values: any = Object.values(data);
    let i;
    for (i = 0; i < visualValueCount; ++i) {
      const value = values[dataMap[i]] / 500;
      const elmStyles: CSSStyleDeclaration = visualElements[i]?.style;
      if (elmStyles) {
        elmStyles.transform = `scaleY( ${value + 0.4} )`;
        elmStyles.opacity = "1";
      }
    }
  }; // process frame end

  const a = new AudioVisualizer(audioContext, processFrame);
}; //init end