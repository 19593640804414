import CavuLogo from '../../../assets/images/cavu-logo.png';
import EltropyLogo from '../../../assets/images/eltropy.png';
import styled from 'styled-components';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { Heading4 } from '../../on-board';
import { useHistory } from 'react-router-dom';
import { useSetState } from '../../../store';

export const HeaderContainer = styled.div`
position: fixed;
top: 0;
left: 0;
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 20px;
z-index: 10;
`;

export const HeaderLeft = styled.div`
display: flex;
align-items:center;
`;

export const LogoContainer = styled.div`
height: 60px;
`;

export const Logo = styled.img`
max-width: 100%;
max-height: 100%;
`;

export const EndCallButton = styled.a`
padding: 10px 10px;
background: #EF5A51;
color: white;
// color: red;
border-radius: 50px;

:hover {
    color: white;
    opacity: 0.8;
}

svg {
    margin: 0 0 0 10px;
    // background: red;
    // color:white;
}

span {
    font-weight: bold;
    margin: 0 10px;
}
`;

export const Header = () => {
    const setState = useSetState();
    const history = useHistory();

    function onEndCall(_e: any) {
        _e.preventDefault();
        history.push("/");
        setState(prev => ({ ...prev, feedback: true }))
    }
    return (
        <HeaderContainer>
            <HeaderLeft>
                <LogoContainer>
                    <Heading4><span>MCU</span> | <small>My Credit Union</small></Heading4>
                </LogoContainer>
            </HeaderLeft>
            <EndCallButton href="/" onClick={onEndCall}>
                <CallEndIcon />
                <span>End Call</span>
            </EndCallButton>
        </HeaderContainer>
    )
}
