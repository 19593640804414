import React from 'react';
import './index.css';
import { UneeqProvider } from './store';
import { ApplicationContainer } from './views/App';
import { Toolbar } from './views/Toolbar';

export const AppMain = () => {
    return (
        <UneeqProvider>
            {/* <Toolbar /> */}
            <ApplicationContainer />
        </UneeqProvider>
    );
}