import { useTrackedState } from '../store';
import styled from 'styled-components';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import React from 'react';
import classNames from 'classnames';
import { ChatHeader } from './chat-box/ChatHeader';
import { ChatArea } from './chat-box/ChatArea';
import { ChatInput } from './chat-box/ChatInput';
import { AudioVisualizerComponent } from './Waveform';

export const ChatContainer = styled.div`
background-color: var(--chat-background);
// border: 1px #11304F solid;
box-shadow: 0 0 32px rgba(0,0,0,0.05);
border-radius: 16px;
display: flex;
flex-direction: column;
height: 100%;
`;

export const ChatToggleButton = styled.button`
position: absolute;
right: 32px;
bottom: 24px;
border: none;
background-color: var(--chat-background);
border-radius: 4px;
padding: none;
color: var(--message-text);
width: 40px;
height: 40px;
justify-content: center;
align-items: center;
/* display: none; */
z-index: 101;

.show {
    display: flex;
}

@media screen and (max-width: 900px) {
    display: block;
}

@media screen and (max-width: 520px) {
    right: 16px;
}
`;

export type ChatBoxProps = {
    togglechat: any;
    settogglechat: (arg0: (prevState: any) => boolean) => void;
    micEnable: boolean
}

const ChatBox: React.FC<ChatBoxProps> = (props: {
    togglechat: any;
    settogglechat: (arg0: (prevState: any) => boolean) => void;
    micEnable: boolean;
}) => {

    const state = useTrackedState();
    const chatBox = classNames({
        "right-side": true,
        "show": props.togglechat
    })

    function toggleChatBox(_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        props.settogglechat((prevState: any) => {
            return !prevState;
        })
    }

    return (
        <React.Fragment>
            <div className={chatBox}>
                <ChatContainer>
                    <ChatHeader />
                    <ChatArea transcript={state.transcription} />
                    {state.chatMode ? <ChatInput /> : <AudioVisualizerComponent enable={props.micEnable} />}
                </ChatContainer>
            </div>

            {/* Toggle button for chat box / chat container */}
            {/* <ChatToggleButton onClick={(e) => toggleChatBox(e)}>
                <ChatBubbleOutlineOutlinedIcon />
            </ChatToggleButton> */}
        </React.Fragment>
    )
}

export default ChatBox;
