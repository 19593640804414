import { Tag } from 'antd';
import styled from 'styled-components';
import { useTrackedState } from '../../store';

export const ChatHeaderContainer = styled.div`
padding: 16px;
border-bottom: 1px solid #f5f5f5;
`;

export const ChatHeader = () => {
    const state = useTrackedState();
    return (
        <ChatHeaderContainer>
            {state.chatMode ?
                <Tag color={"geekblue"}>Chatting with Kaitlin</Tag>
                :
                <Tag color={"orange"}>Speaking with Kaitlin</Tag>
            }
        </ChatHeaderContainer>
    )
}
