import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSetState, useTrackedState } from './store';
import "./feedback.css";

export const Feedback: React.FC = () => {
  const state = useTrackedState()
  const setState = useSetState();

  return (
    <>
      <Modal title="How satisfied were you with the assistance?"
        centered
        visible={state.feedback}
        bodyStyle={{padding: "16px 24px"}}
        closable={false}
        footer={null}>
        <form>
          <div className="feedback-level">
            <div className="level">
              <i className="lar la-sad-tear"></i>
            </div>
            <div className="level">
              <i className="las la-frown"></i>
            </div>
            <div className="level">
              <i className="lar la-meh"></i>
            </div>
            <div className="level">
              <i className="lar la-smile"></i>
            </div>
            <div className="level">
              <i className="lar la-grin"></i>
            </div>
          </div>

          <div className="feedback-msg">
            <textarea name="" id="" placeholder='Comments...'></textarea>
          </div>

          <div className="agreement">
            <div className="checkbox">
              <input type="checkbox" name="" id=""></input>
              <label>I may be contacted about this feedback <a href="#">Privacy Policy</a>.</label>

            </div>
            <div className="checkbox">
              <input type="checkbox" name="" id=""></input>
              <label>I'd like to help improve by joining the <a href="#">Reasearch Group</a>.</label>

            </div>
          </div>

          <div className="buttons">
            <a href="#" onClick={() => setState(prev => ({ ...prev, feedback: false }))}>Cancel</a>
            <a onClick={() => setState(prev => ({ ...prev, feedback: false }))}>Submit</a>
          </div>
        </form>
      </Modal>
    </>
  );
};